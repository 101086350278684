import * as React from 'react';
import { Props } from "cleave.js/react/props";
const Cleave = require("../../../node_modules/cleave.js/react");
//import { Props } from "../../../node_modules/@types/cleave.js/react/props";


export const SaCreditCardNumber: React.FunctionComponent<Props> = (props: Props) => {    

    const handleCreditCardTypeChange = (type: any) => {

        const amexImg = document.getElementById("amex-img");
        const visaImg = document.getElementById("visa-img");
        const mastercardImg = document.getElementById("mastercard-img");
        const discoverImg = document.getElementById("discover-img");
        switch (type) {
            case "amex":
                if (amexImg) { amexImg.style.display = "block"; }
                break;
            case "visa":
                if (visaImg) visaImg.style.display = "block";
                break;
            case "mastercard":
                if (mastercardImg) mastercardImg.style.display = "block";
                break;
            case "discover":
                if (discoverImg) discoverImg.style.display = "block";
                break;
            default:
                if (amexImg) amexImg.style.display = "none";
                if (discoverImg) discoverImg.style.display = "none";
                if (mastercardImg) mastercardImg.style.display = "none";
                if (visaImg) visaImg.style.display = "none";
        }

    }

    return (<div style={{ display: "flex" }}>
        <div style={{ flexGrow:9 }}>
        <Cleave
            placeholder="Enter credit card number"
            options={{
                creditCard: true,
                onCreditCardTypeChanged: handleCreditCardTypeChange
            }} className="form-control"
            onChange={props.onChange}
            key="number" id="number" name="number"
        />
    </div>
        <div style={{ flexGrow:0 }}>
            <img style={{ display: "none" }} id="amex-img" src="/img/amex.PNG" alt="Amex" />
            <img style={{ display: "none" }} id="visa-img" src="/img/visa.PNG" alt="Visa" />
            <img style={{ display: "none" }} id="discover-img" src="/img/discover.PNG" alt="Discover" />
            <img style={{ display: "none" }} id="mastercard-img" src="/img/mastercard.PNG" alt="Mastercard" />
        </div>
    </div>);
}