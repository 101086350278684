export interface AzureDocument {

    companyCode: string,
    entityType: number,
    entityCode: string,
    documentId: number,
    documentName: string,
    fileName: string,
    fileExtension: string,
    cloudReference: string,
    documentShortDescription: string,
    module: string
}

export const initialState: AzureDocument = {
    companyCode: '',
    entityType: 0,
    entityCode: '',
    documentId: 0,
    documentName: '',
    fileName: '',
    fileExtension: '',
    cloudReference: '',
    documentShortDescription: '',
    module: ''
};

export interface AzureDocumentList {
    items: AzureDocument[],
    error: string,
    loading: boolean


}

export const initialListState: AzureDocumentList = {
    items: [],
    error: '',
    loading: false
}