import { sawinFunc } from "./sawinLib";
import * as myCfg from '../AppConfig';
import { text } from "@fortawesome/fontawesome-svg-core";


export function retrieveInvoicePDF(companyCode:string, invoiceNumber: string) {
        //const appState = getState();          
       
    //sawinFunc.fetch(myCfg.appSettings.webApiServerBaseUrl + 'api/PDF/Invoice?pInvoiceNum=' + invoiceNumber + '&pCompanyCode=' + companyCode,
    //        {
    //            method: 'GET',                        
    //        })
    //        .then(res => res.blob())
    //        .then(blob => {
    //            var file = window.URL.createObjectURL(blob);
    //            window.location.assign(file);
    //        });

    sawinFunc.fetch(myCfg.appSettings.webApiServerBaseUrl + 'api/PDF/Invoice?pInvoiceNum=' + invoiceNumber + '&pCompanyCode=' + companyCode,
        {
            method: 'GET',
            headers: { 'Content-Type': 'text/html; charset=utf-8' }

        })
        .then(res => res.text())
        .then(text => window.location.href = text);
            //window.open(text, '_blank'));    
};