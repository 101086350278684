import * as ServiceContractMdls from './ServiceContractModel';

export interface ServiceContractRenewViewModel {
    oldContract: ServiceContractMdls.ServiceContractModel,
    newContract: ServiceContractMdls.ServiceContractModel,
    loading: boolean,
    processing: boolean,
    result: string
}

export const ServiceContractRenewViewModelInitialState: ServiceContractRenewViewModel = {
    oldContract: { ...ServiceContractMdls.initialState },
    newContract: { ...ServiceContractMdls.initialState },
    loading: false,
    processing: false,
    result: ''
}