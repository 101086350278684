

export interface ServiceContractMaintScheduleModel {
    contractNumber: string,
    contractSequence: number,
    maintenanceDescription: string,
    taskDescription: string,
    scheduleDate: string,
    maintenanceDone: boolean,
    callNumber: number,
    allowToBookCall: boolean,
    taskNum: string,
    startDateRange?: Date,
    endDateRange?: Date,    
}

export const initialState: ServiceContractMaintScheduleModel = {
    contractNumber: '',
    contractSequence: 0,
    maintenanceDescription:'',
    taskDescription: '',
    scheduleDate: '',
    maintenanceDone: false,
    callNumber: 0,
    allowToBookCall: false,
    taskNum: '',    
}

export interface ServiceContractMaintScheduleList {
    items: ServiceContractMaintScheduleModel[];
    loading: boolean;
}

export const maintScheduleinitialState: ServiceContractMaintScheduleList = {
    items: [],
    loading: false
}