import * as React from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import  MainHeader from './MainHeader/MainHeader';
import * as ARMdl from '../models/BillToModel';
import * as SMMdl from '../models/ServiceMasterModel';


type MyProps = React.ReactNode;


class Layout extends React.PureComponent<React.ReactNode> {
    public render() {
        return (
            <React.Fragment>
                <MainHeader/>
                {this.props.children}                
            </React.Fragment >
        );
    }
}


export default Layout;

/*
export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <MainHeader /> 
        <Container>
            {props.children}            
        </Container>
    </React.Fragment>
);

*/