
export interface CustomerRequestInitialData {
    items: CustomerRequestInitialDataItem
    loading: boolean,
    error: string,
    loaded: boolean
}

export interface CustomerRequestInitialDataItem {
    serviceRequestMessage: string
    timePromissedOptions: CustomerRequestInitialDataTimePromissed[],
    taskInfoList: CustomerRequestTaskInfoItem[]
}

export interface CustomerRequestTaskInfoItem {
    category: string,
    description: string,
    funcionCode: string,
    componentCode: string
}


export interface CustomerRequestInitialDataTimePromissed {
    code: string,
    description: string,
    isAvailable: boolean
}

export const initialInfo: CustomerRequestInitialData = {
    items: { serviceRequestMessage: '', timePromissedOptions: [], taskInfoList: [] },
    loading: false,
    loaded: false,
    error: ''
}
