import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Button, ButtonGroup, Col, Collapse, Container, Modal, ModalBody, ModalHeader, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { BillToList } from '../../models/BillToModel';
import { SessionModel } from '../../models/SessionModel';
import * as UsrStore from '../../store/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCog } from '@fortawesome/free-solid-svg-icons'


type MyProps =
    BillToList & SessionModel & typeof UsrStore.actionCreators;


export interface MainHeaderState {
    menuOpen: boolean;    
}

class MainHeader extends React.PureComponent<MyProps> {

    state: MainHeaderState = { menuOpen: false };

    constructor(props: MyProps) {
        super(props);        
    }

    toggleMenu() {                
        this.setState({ menuOpen: !this.state.menuOpen });        
    }

    logOff() {
        this.props.logOff();        
    }

    public render() {
        return ( 
            <React.Fragment>
                <div className="headerstyle">
                    <Container fluid={true}>
                        <Row>
                            <Col xs="10" className="headerCompanyName">
                                {this.props.SelectedAR.sawinClientName}
                            </Col><Col xs="2" className="headerMenu">
                                <Button size="sm" onClick={() => this.toggleMenu()} ><FontAwesomeIcon icon={faBars} /></Button>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Modal isOpen={this.state.menuOpen} toggle={() => this.toggleMenu()} style={{ width: "200px", margin: "auto", top:"200px"}}>                    
                    <ModalBody style={{padding: "0px"}}>
                        <ButtonGroup vertical className="w-100">                 
                            <Button onClick={() => this.logOff()} outline={true}>Log Off</Button>                            
                        </ButtonGroup>
                        <div>v0.1</div>
                    </ModalBody>               
                </Modal>
            </React.Fragment>
        );

    }
};

function mapStateToProps(state: ApplicationState) {
    return { ...state.billToList , ...state.sessionState }
};

export default connect(mapStateToProps, UsrStore.actionCreators)(MainHeader as any);
/*    (state: ApplicationState) => state.ArInfo,
)(ARSelector);*/

