export interface AdditionalUser {

    companyCode: string,
    phoneNumber: string,
    phoneLabel: string,
    sequence: number,
    customerNumber: string,
    serviceMasterNumber: string,
    lastLogin?: Date,
    registeredOn?: Date,
    error: string,
    processing: boolean,
    success: string

}

export const initialState: AdditionalUser = {
    companyCode: '',
    phoneNumber: '',
    phoneLabel: '',
    sequence: 0,
    customerNumber: '',
    serviceMasterNumber: '',
    lastLogin: undefined,
    registeredOn: undefined,
    error: '',
    processing: false,
    success: ''
};


export interface AdditionalUserList {
    items: AdditionalUser[],
    error: string,
    loading: boolean
}

export const initialListState: AdditionalUserList = {
    items: [],
    error: '',
    loading: false    
}