import * as CustomerRequestMdls from '../models/CustomerRequestBucket';
 
export interface CustomerRequestBucketListModel {
    items: CustomerRequestMdls.CustomerRequestBucketModel[],
    error: string,
    loading: boolean
}

export const initialState: CustomerRequestBucketListModel = {
    items: [],
    error: '',
    loading: false
}
