import * as myCfg from '../AppConfig';
import { ErrorModel } from '../models/GenericModels';

export interface ResetStore { type: 'STORE_RESET' };
export interface LogProps { message: string, logType: string, clientMessage: string }
export enum LogTypes { Error='E', Warning='W', Info='I' }

export enum CreditCardProcessStatus {
    NotStarted,
    InProcesses,
    Finished
}

export interface APIContainer {
      success: boolean,
    errorInfo: ErrorModel,
        data:any
}

export const sawinFunc = {

    fetch: (input: RequestInfo, init?: RequestInit | undefined) => {

        var myCompany: string = String(localStorage.getItem("Company"));
        var UserStoredJSON = localStorage.getItem("UserStored");
        var userSettings = { "Phone": '', "Password": '' };

        if (UserStoredJSON != null) {
            userSettings = JSON.parse(UserStoredJSON);
        }

        if (init != undefined) {
            var newFeed = init;
            newFeed.headers = {
                ...newFeed.headers, ...userSettings, ...{ 'clientCompanyName': myCompany },
            };

            return fetch(input, newFeed)
                /*   .then(response => {
                       if (response.status >= 200 && response.status < 300) {
                           return Promise.resolve(response)
                       } else {
                           return Promise.reject(new Error(response.statusText))
                       }
                   })*/
                .then(response => { return response });
        } else {
            return fetch(input)
                /*.then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        return Promise.resolve(response)
                    } else {
                        return Promise.reject(new Error(response.statusText))
                    }
                })*/
                .then(response => { return response });
        }
    },

    log: (props: LogProps) => {

        if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
            if (props.clientMessage) {
                alert(props.clientMessage);
            }
            switch (props.logType) {
                case LogTypes.Error:
                    console.error(props.message);
                    break;
                case LogTypes.Info:
                    console.info(props.message);
                    break;
                case LogTypes.Warning:
                    console.warn(props.message);
                    break;
                default:
                    break;
            }
        } else { //DEV ENVIRONMENT
            alert(props.message);
        }

    }
}

