import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SessionModel } from '../../models/SessionModel';
import * as RecommendationStore from '../../store/Recommendation/recommendation';
import { ApplicationState } from '../../store';
import { RecommendationModel } from '../../models/RecommendationModel';
import { LoadingIcon } from '../Misc/Loading';
import { SaDate, SaLabel } from '../Misc/SawinComponents';

type MyProps =
    { RecmdList: RecommendationStore.RecommendationList } & { SessionState: SessionModel } & typeof RecommendationStore.actionCreators;

class Recommendation extends React.PureComponent<MyProps> {

    constructor(props: MyProps) {
        super(props);
        
    }

    componentDidMount() {
        this.props.loadRecommendation(this.props.SessionState);
    }

    

    public render() {        

        return (
            <Container fluid={true}>
                <LoadingIcon loading={this.props.RecmdList.loading} title={this.props.SessionState.SelectedSM.id + " - " + this.props.SessionState.SelectedSM.name.displayName} address={this.props.SessionState.SelectedSM.address}/>
                <Col>
                    {this.props.RecmdList.items.map((item, index) =>
                        <Row key={index}>
                            <RecommendationCard key={item.id} item={item} companyCode={this.props.SessionState.SelectedAR.companyCode} ></RecommendationCard>
                        </Row>
                    )}
                </Col>
            </Container>
            );
        
    }
}


export default connect(
    (state: ApplicationState) => ({ ...{ RecmdList: state.recommendationState }, ...{ SessionState: state.sessionState } }),
    RecommendationStore.actionCreators)(Recommendation as any);


class RecommendationCard extends React.PureComponent<{ item: RecommendationModel, companyCode: string }> {

    
    public render() {
        
        return (<Card className='recommendation-card'>
            <CardHeader>
                <Row>
                    <Col><SaLabel Text={"Given By"} />
                        {this.props.item.madeBy} 
                    </Col>
                    <Col className="right-align">
                        <SaLabel Text={"Given On"} />
                        <SaDate Value={this.props.item.date}  />
                    </Col>
                   
                </Row>
            </CardHeader> 
            <CardBody>
                
                <Row>
                    <Col>
                        <SaLabel Text={"Description"} /> {this.props.item.description}
                    </Col>
                </Row>
                
            </CardBody>
               
        </Card>);
    }
}