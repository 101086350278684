import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { SessionModel } from '../../models/SessionModel';
import * as CallHistoryStore from '../../store/CallHistory';
import { CallModel } from '../../models/CallModel';
import * as PDFReports from '../../store/PDFReports';
import { LoadingIcon } from '../Misc/Loading';
import { SaCurrency, SaDate, SaLabel } from '../Misc/SawinComponents';
import { useEffect } from 'react';

type MyProps =
    { CallHistoryList: CallHistoryStore.CallHistoryList } & { SessionState: SessionModel } & typeof CallHistoryStore.actionCreators;

const CallHistory: React.FunctionComponent<MyProps> = (props: MyProps) => {

        const location = useLocation();

        useEffect(() => {

        if (location.state != undefined) {
            props.loadHistoryForEqp(props.SessionState, location.state.EqpLog);
            
        } else {            
            props.loadHistory(props.SessionState);
        }

    }, []);

   
        return (
            <Container fluid={true}>
                <LoadingIcon loading={props.CallHistoryList.loading}
                    title={props.SessionState.SelectedSM.id + " - " + props.SessionState.SelectedSM.name.displayName}
                    address={props.SessionState.SelectedSM.address }  />
                <Col>  
                    {props.CallHistoryList.items.map((item, index) =>
                        <Row key={index}>
                            <CallHistoryCard item={item} companyCode={props.SessionState.SelectedAR.companyCode} > </CallHistoryCard>
                        </Row>
                    )}
                </Col>
            </Container>
        );
    }
 

class CallHistoryCard extends React.PureComponent<{ item: CallModel, companyCode: string}> {


    handleClick = (companyCode:string, invoiceNum: string) => {            
        PDFReports.retrieveInvoicePDF(companyCode, invoiceNum);        
    };

    public render() {
        

/*        var startDate = new Date(this.props.item.startDate).toLocaleDateString();
        var endDate = new Date(this.props.item.endDate).toLocaleDateString();

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',           
        });

        var amount = formatter.format(this.props.item.invoice.total);*/    
        return (<Card className='callHistory-card'>
            <CardHeader>
                <Row>
                    <Col xs="4">
                        <SaLabel Text={"Call"}/> {this.props.item.id}
                    </Col>
                    <Col xs="8" className="right-align">
                        <SaLabel Text={"Service Date"} />  <SaDate Value={this.props.item.startDate} /> to <SaDate Value={this.props.item.endDate} />
                    </Col>
                </Row>                
            </CardHeader>
            <CardBody>                
                <Row>
                    <Col>
                        <SaLabel Text={"Person Calling"} /> {this.props.item.contactPerson}
                    </Col>                  
                </Row>
                <Row>
                    <Col className='callHistoryInvoiceLink' onClick={() => this.handleClick(this.props.companyCode, this.props.item.invoice.id)}>                        
                        <SaLabel Text={"Invoice"} />  {this.props.item.invoice.id}                        
                    </Col>
                    <Col className="right-align"> <SaLabel Text={"Amount"} /> <SaCurrency key={this.props.item.id } Amount={this.props.item.invoice.total} /></Col>                    
                </Row>    
                <Row>
                    <Col>
                        <SaLabel Text={"Work Description"} />{this.props.item.invoice.workDescription}
                    </Col>                    
                </Row>    
            </CardBody>
            <CardFooter style={{ textAlign: 'center' }}>


                    <Link to={{ pathname: '/assets', state: { assets: this.props.item.invoice.assets } }} ><Button>Assets {this.props.item.invoice.assets != null ? this.props.item.invoice.assets.length : 0}</Button></Link>

             
            </CardFooter>
        </Card>);
    }
}

export default connect(
    (state: ApplicationState) => ({ ...{ CallHistoryList: state.callHistoryState }, ...{ SessionState: state.sessionState } }),
    CallHistoryStore.actionCreators)(CallHistory as any);
