import * as React from 'react';
import { Route } from 'react-router';
import * as UserStore from './store/User';
import { connect } from 'react-redux';
import { ApplicationState } from './store';
import './custom.css';
import './customerPortal.css';

import WidgetsScreen from './components/WidgetScreen/MainWidgetsScreen';
import Layout from './components/Layout';
import LogIn from './components/LogIn/LogIn';
import CallHistory from './components/CallHistory/CallHistory';
import EquipmentComponent from './components/Equipment/Equipment';
import Recommendation from './components/Recommendation/Recommendation';
import ServiceContractScreen from './components/ServiceContract/ServiceContract';
import ARPicker from './components/ARPicker/ARPicker';
import SMPicker from './components/SMPicker/SMPicker';
import OpenInvoicesUI from './components/OpenInvoices/OpenInvoices';
import MXMerchant from './components/MXMerchant/MXMerchant';
import ServiceContractRenewScreen from './components/ServiceContractRenew/ServiceContractRenew';
import ServiceContractRenewChargeScreen from './components/ServiceContractRenewCharge/ServiceContractRenewCharge';
import ServiceContractSchedule from './components/ServiceContractMaintenanceSchedule/ServiceContractMaintenanceSchedule';
import ServiceContacts from './components/ServiceContact/ServiceContact';
import CustomerRequest from './components/CustomerRequest/CustomerRequest';
import CustomerRequestWB from './components/CustomerRequestWB/CustomerRequestWB';
import CustomerRequestMaint from './components/CustomerRequestMaint/CustomerRequestMaint';
import CustomerRequestList from './components/CustomerRequestList/CustomerRequestList';
import PendingAppointment from './components/PendingAppointment/PendingAppointment';
import CustomerFeedback from './components/CustomerFeedback/CustomerFeedback';
import CustomerFeedbackList from './components/CustomerFeedback/CustomerFeedbackList';
import ServiceContactEntry from './components/ServiceContact/ServiceContactEntry';
import PaidInvoices from './components/PaidInvoices/PaidInvoices';
import AdditionalUserList from './components/AdditionalUsers/AdditionalUserList';
import AdditionalUser from './components/AdditionalUsers/AdditionalUser';
import { CreditCardProcessStatus } from './models/CreditCardModel';
import AzureDocuments from './components/AzureDocumentList/AzureDocumentListComponent';
import AccessRequest from './components/AccessRequest/AccessRequest';
import AssetsComponent from './components/Assets/AssetsComponent';
import FullSteamPay from './components/FullSteamPay/FullSteamPay';

type AppProps =
    UserStore.UserState &
    typeof UserStore.actionCreators; 

class App extends React.PureComponent<AppProps> {
    public render() {
        
        if (this.props.IsLoggedIn !== true) {
            if (this.props.RequestForAccess == true) {
                return (
                    <React.Fragment>
                        <AccessRequest />
                    </React.Fragment>);
            } else {
                return (
                    <React.Fragment>
                        <LogIn />
                    </React.Fragment>
                );
            }
        } else {
            return (<Layout>
                <Route exact path='/' component={WidgetsScreen} />              
                <Route path='/logIn' component={LogIn} />
                <Route path='/history' component={CallHistory} />                
                <Route path='/equipment' component={EquipmentComponent} />
                <Route path='/Recommendations' component={Recommendation} />
                <Route path='/Contract' component={ServiceContractScreen} />
                <Route path='/ARPicker' component={ARPicker} />
                <Route path='/SMPicker' component={SMPicker} />
                <Route path='/Invoices-Open' component={OpenInvoicesUI} />
                <Route path='/MXMerchant' component={MXMerchant} />
                <Route path='/ServiceContractRenew/:oldContractNum' component={ServiceContractRenewScreen} />
                <Route path='/ServiceContractRenewCharge' component={ServiceContractRenewChargeScreen} />
                <Route path='/ServiceContractSchedule' component={ServiceContractSchedule} />
                <Route path='/Contacts' component={ServiceContacts} />
                <Route path='/ContactEntry' component={ServiceContactEntry} />
                <Route path='/RequestServiceEntry' component={CustomerRequest} />                
                <Route path='/Request/:requestType' component={CustomerRequestList} />
                <Route path='/Pending-Appointments' component={PendingAppointment} />
                <Route path='/Feedback' component={CustomerFeedbackList} />
                <Route path='/FeedbackEntry' component={CustomerFeedback} />
                <Route path='/Invoices-Paid' component={PaidInvoices} />
                <Route path='/Add-User' component={AdditionalUserList} />                
                <Route path='/AdditionalUserEntry' component={AdditionalUser} />
                <Route path='/Whiteboard' component={CustomerRequestWB} />
                <Route path='/BookMaintenance' component={CustomerRequestMaint} />
                <Route path='/myQuotes' component={AzureDocuments} />
                <Route path='/AccessRequest' component={AccessRequest} />
                <Route path='/Assets' component={AssetsComponent} />
                <Route path='/FullSteamPay' component={FullSteamPay} />
            </Layout>
            );
        }
    }
};

export default connect(
    (state: ApplicationState) => state.user,
    UserStore.actionCreators
)(App as any);





