var localSettings = { webApiServerBaseUrl: "" };

if (process.env.NODE_ENV === 'production') {
//    localSettings = { webApiServerBaseUrl: "https://www.saimobile2.com:82/" };
    localSettings = { webApiServerBaseUrl: "/" };
} else {
    localSettings = { webApiServerBaseUrl: "/" };
}

export const appSettings = localSettings;

export const appVersion = "v0.50";