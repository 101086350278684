
export interface CustomerFeedbackModel {
    companyCode: string,
    serviceMaster: string,
    invoiceNum: string,
    contactPerson: string,
    contactPersonPhone: string,
    customerComments: string,
    commentsGivenOn: string,
    responseGiven: boolean,
    responseGivenBy: string,
    responseGivenOn: string,
    responseMessage: string,
    responsePartyPhone: string,
    processing: boolean,
    error: string,
    success: string
}

export const initialState: CustomerFeedbackModel = {
    companyCode: '',
    serviceMaster: '',
    invoiceNum: '',
    contactPerson: '',
    contactPersonPhone: '',
    customerComments: '',
    commentsGivenOn: '',
    responseGiven: false,
    responseGivenBy: '',
    responseGivenOn: '',
    responseMessage: '',
    responsePartyPhone: '',
    processing: false,
    error: '',
    success: ''
}

export interface CustomerFeedbackListModel {
    items: CustomerFeedbackModel[],
    error: string,
    loading: boolean
}

export const initialListState: CustomerFeedbackListModel = {
    items: [],
    error: '',
    loading: false
}