import * as GenModel from '../models/GenericModels';
import * as SMModel from '../models/ServiceMasterModel';

export interface PendingAppointmentModel {
    servMasterNum: SMModel.ServiceMaster,
    workOrderNum: number,
    problemDescription: string,
    taskDescription: string,
    timePromised: string,
    personCalling: string,
    personCallingContactNumber: string,
    datePromisedStart: string,
    datePromisedEnd: string,
}


export const initialState: PendingAppointmentModel = {
    
    servMasterNum: { ...SMModel.initialState },
    workOrderNum: 0,
    problemDescription: '',
    taskDescription: '',
    timePromised: '',
    personCalling: '',
    personCallingContactNumber: '',
    datePromisedStart: '',
    datePromisedEnd: ''
};     

export interface PendingAppointmentList {
    items: PendingAppointmentModel[];
    loading: boolean;
 
}

export const initialPendingAppointmentState: PendingAppointmentList = {
    items: [],
    loading: false,
 
};
