export interface AccessRequestModel {
    firstName: string,
    lastName: string,
    address: string,
    city: string,
    state: string,
    homePhone: string,
    cellPhone: string,
    email: string
}

export const initialState: AccessRequestModel = {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    homePhone: '',
    cellPhone: '',
    email: ''
}