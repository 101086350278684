
export interface CustomerRequestBucketModel {
    companyCode: string,
    referenceId: string,
    contactName: string,
    contactPhone: string,
    serviceOrProposal: string,
    serviceMasterNum: string,
    descriptionOfWork: string,
    requestedTime: string,
    requestDate?: Date,
    urlPath1: string,
    urlPath2: string,
    urlPath3: string,
    taskCategory: string,
    taskCode: string,
    requestStatus: string,
    commentsFromCompany: string,
    callOrQuoteNumber: string,
    requestMakeOn: string,
    processing: boolean,
    error: string,
    success: string,
    serviceContractInfo:string
}

export const initialState: CustomerRequestBucketModel = {
    companyCode: '',
    referenceId: '',
    contactName: '',
    contactPhone: '',
    serviceOrProposal: '',
    serviceMasterNum: '',
    descriptionOfWork: '',
    requestedTime: '',
    requestDate: undefined,
    urlPath1: '',
    urlPath2: '',
    urlPath3: '',
    taskCategory: '',
    taskCode:'',
    requestStatus: '',
    commentsFromCompany: '',
    callOrQuoteNumber: '',
    requestMakeOn: '',
    processing: false,
    error: '',
    success: '',
    serviceContractInfo: ''
}