import * as ServMasterModel from './ServiceMasterModel';

export interface ServiceContactModel {
    companyCode: string,
    serviceMaster: ServMasterModel.ServiceMaster,
    sequence: number,
    email: string,
    phoneNumber: string,
    phoneExt: string,
    workMobileNumber: string,
    mobilNumber: string,
    contactName: string,    
    notes: string,
    workPhoneNumber: string,    
    processing: boolean,
    error: string,
    success: string
}

export const initialState: ServiceContactModel = {
    companyCode: '',
    serviceMaster: { ...ServMasterModel.initialState },    
    sequence: 0,
    email: '',
    phoneNumber: '',
    phoneExt: '',
    workMobileNumber: '',
    mobilNumber: '',
    contactName: '',
    notes: '',
    workPhoneNumber: '',
    processing: false,
    error: '',
    success: ''
};

export interface ServiceContactList {
    items: ServiceContactModel[],
    loading: boolean,
    error: string
}


export const initialStateServiceContactList = {
    items: [],
    loading: false,
    error:''
}